<template>
  <v-main>
    <v-container fill-height>
      <v-card class="mx-auto d-flex flex-column col-sm-6">
        <v-card-title class="display-1 align-self-center">Finance Invoice System</v-card-title>

        <v-card-text>
          <v-alert type="error" v-if="error" dense text>{{ error }}</v-alert>
          <v-btn @click="loginWithAzure()" :loading="btnLoginLoading" outlined block>เข้าสู่ระบบด้วย Azure AD</v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import router from "../router";
import APIClient from "@/core/base/BaseAPIService";
import { loginWithAzure } from "@/core/msal";

export default {
  name: "LoginView",
  created() {
    sessionStorage.clear();
  },
  data() {
    return {
      btnLoginLoading: false,
      error: null,
    };
  },
  methods: {
    async loginWithAzure() {
      this.error = null;
      this.btnLoginLoading = true;
      try {
        const loginResponse = await loginWithAzure();
        await APIClient(loginResponse.idToken)
          .get("/user/info")
          .then((response) => {
            sessionStorage.setItem("user", JSON.stringify(response.data.result));
            router.push({ name: "FinanaceHistory" });
          })
          .catch((err) => {
            this.error = err.response.data.message;
          });
      } catch (error) {
        console.error(error);
      } finally {
        this.btnLoginLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.v-main {
  height: 100%;
  background: radial-gradient(circle, var(--v-secondary-base) 0%, var(--v-primary-base) 100%) !important;
}
</style>
