import * as msal from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_AZURE_AD_CLIENT_ID,
    authority: process.env.VUE_APP_AZURE_AD_AUTHORITY,
    redirectUri: process.env.VUE_APP_AZURE_AD_REDIRECT_URL,
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
    storeAuthStateInCookie: false, // set this to true if you have to support IE
  },
  // system: {
  //   loggerOptions: {
  //     loggerCallback: (level, message, containsPii) => {
  //       if (containsPii) {
  //         return;
  //       }
  //       switch (level) {
  //         case msal.LogLevel.Error:
  //           console.error(message);
  //           return;
  //         case msal.LogLevel.Info:
  //           console.info(message);
  //           return;
  //         case msal.LogLevel.Verbose:
  //           console.debug(message);
  //           return;
  //         case msal.LogLevel.Warning:
  //           console.warn(message);
  //           return;
  //       }
  //     },
  //   },
  // },
};

const msalInstance = msal.PublicClientApplication.createPublicClientApplication(msalConfig);

export const loginWithAzure = async () => {
  try {
    const loginResponse = (await msalInstance).loginPopup({
      scopes: ["User.Read"],
    });
    return loginResponse;
  } catch (error) {
    console.log(error);
  }
};

export const logoutFromAzure = async () => {
  try {
    await (await msalInstance).logout();
  } catch (error) {
    console.error("Logout failed:", error);
  }
};

export default msalInstance;
