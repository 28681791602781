<template>
  <v-app>
    <NavBar v-if="fullname !== null" :fullname="fullname" />
    <LoadingDialog v-model="loading.show" />

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar";
import LoadingDialog from "@/components/dialog/LoadingDialog";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    NavBar,
    LoadingDialog,
  },
  beforeUpdate() {
    this.getFullname();
  },
  data: () => ({
    fullname: null,
  }),
  computed: {
    ...mapGetters({
      loading: "dialog/getLoadingDialog",
    }),
  },
  methods: {
    ...mapActions({
      showLoading: "dialog/showLoadingDialog",
      dismissLoading: "dialog/dismissLoadingDialog",
    }),
    getFullname() {
      const user = JSON.parse(sessionStorage.getItem("user"));
      if (user === null || user === undefined) {
        return;
      }
      this.fullname = user.fullname;
    },
  },
};
</script>
